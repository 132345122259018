<template>
  <div
    v-if="concession.name
    "
  >
    <router-link :to="$objectViewRoute(concession)">
      {{ `${concession.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "location",

  components: {},

  mixins: [formMixin],

  props: {
    location: {
      type: Object,
      default: () => ({}),
      description: "Location",
    },
    purchasesInvoice: {
      type: String,
      default: null,
      description: "purchasesInvoice id",
    },
    salesInvoice: {
      type: String,
      default: null,
      description: "salesInvoice id",
    },
  },

  data() {
    return {
      concession:{}
    };
  },

  setup() {},

  created() {
    if(this.purchasesInvoice || this.salesInvoice){
      this.getLocation();
    }else{
      this.concession = this.location;
    }
  },

  methods: {
    async getLocation(query = null) {
      try {
        this.loading = true;
        let params = {
          filter: {
            ...(query ? { search: query } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
          include: "seller,buyer,sellerLocation,buyerLocation,vehicle",
        };
        if (this.purchasesInvoice) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              seller_invoice_id: this.purchasesInvoice,
            },
          };
        }
        if (this.salesInvoice) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              buyer_invoice_id: this.salesInvoice,
            },
          };
        }
        await this.$store.dispatch("transactions/list", params);
        const transactionsArr = await this.$store.getters["transactions/list"];
        let transactions = [];
        transactionsArr.forEach((transaction) => {
          transactions.push(transaction);
        });
        if(transactions.length > 0){
          let transaction = transactions[0];
          if(this.purchasesInvoice){
            this.concession = transaction.sellerLocation;
          }
          if(this.salesInvoice){
            this.concession = transaction.buyerLocation;
          }
        }
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    }
  },

  watch: {
    location(location) {},
  },
};
</script>
