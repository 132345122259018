export const TAX_TYPE_PERCENTAGE = "PERCENTAGE";
export const TAX_TYPE_AMOUNT = "AMOUNT";
export const TAX_TPS_NAME = "TPS (5%)";
export const TAX_TPS_VALUE = 5;
export const TAX_TVQ_NAME = "TVQ (9.975%)";
export const TAX_TVQ_VALUE = 9.975;

export const INVOICE_STATUS_DRAFT = "DRAFT";
export const INVOICE_STATUS_SENT = "SENT";
export const INVOICE_STATUS_PAIED = "PAIED";
export const INVOICE_STATUS_CANCELED = "CANCELED";
export const INVOICE_STATUS_VALIDATED = "VALIDATED";
export const INVOICE_STATUS_DELIVERED = "DELIVERED";

export const INVOICING_TYPE_COMPLETE = "COMPLETE";
export const INVOICING_TYPE_PRODUCT = "PRODUCT";
export const INVOICING_TYPE_AMOUNT = "AMOUNT";

export const INVOICE_TYPE_DOWNPAYMENT = "DOWNPAYMENT";
export const INVOICE_TYPE_STANDARD = "STANDARD";

export const invoiceStatusesOption = {
  [INVOICE_STATUS_DRAFT]: "Brouillon",
  [INVOICE_STATUS_SENT]: "Envoyée",
  [INVOICE_STATUS_PAIED]: "Payée",
  [INVOICE_STATUS_CANCELED]: "Annulée",
  [INVOICE_STATUS_VALIDATED]: "Validée",
  [INVOICE_STATUS_DELIVERED]: "Livrée",
};
